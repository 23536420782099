
import {defineComponent, onMounted, ref} from 'vue';
import {getJobDetail} from "@/api/webIndex";
import {useRoute} from "vue-router";

export default defineComponent({
  name: 'joinDetail',
  setup() {
    const route = useRoute()

    const
        jobDetail = ref<any>({});

    const
        onGetDetail = async (id: any) => {
          try {
            let res = await getJobDetail({id})
            if (res.message === 'ok') {
              jobDetail.value = res.data
            }
          } catch (e) {
            console.log(e)
          }
        }

    onMounted(() => {
      onGetDetail(route.query && route.query.id)
    })

    return {
      jobDetail
    };
  }
});
